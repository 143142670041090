module.exports = {
  siteTitle: "Secret Clan NFT",
  shortSiteTitle: "BushidoClub", // <title> ending for posts and pages
  siteDescription: "Building Software products with the philosophy in mind.",
  siteUrl: "https://secretClan",
  pathPrefix: "",
  siteImage: "../../src/images/webp/1.webp",
  siteLanguage: "en",

  /* author */
  authorEmail: "SecretClanNFT@gmail.com",
  authorName: "Secret Clan",
  authorTwitterAccount: "SecretClanNFT",

  /* info */
  headerTitle: "Secret Clan NFT",
  headerSubTitle: "13 Warriors Secret Clan",

  /* manifest.json */
  manifestName: "Secret Clan NFT",
  manifestShortName: "SecretClan", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",
  gravatarImgMd5: "",

  socialLinks: {
    twitter: 'https://twitter.com/secretclannft',
    discord: 'https://discord.gg/7j9z4W2Gna',
  },
};
