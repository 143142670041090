import * as React from "react"
import * as globalStyles from '../../styles/index.module.css'
import './index.css'

const FAQSection = () => (
  <section className={`faq-section ${globalStyles.container}`}>
    <p className="faq-section-title">F.A.Q.</p>
    <ul className="questions-list">
      <li className="question-block">
        <p className="question">
          WHY IS THERE A FEE TO ENTER THE COMMUNITY?
        </p>
        <p className="answer">
        This small charge prevents any bots, cheats, phonies, or lurkers from joining our community. This way, you can rest assured that the people around you are only serious minters with Sol when you join Secret Clan.
        </p>
      </li>
      <li className="question-block">
        <p className="question">
          What is the total supply?
        </p>
        <p className="answer">
          A total of 9000 unique Secret Clan warriors will be algorithmically generated.
        </p>
      </li>
      <li className="question-block">
        <p className="question">
          When is the launch?
        </p>
        <p className="answer">
          Launch Date - To Be Announced
        </p>
      </li>
      <li className="question-block">
        <p className="question">
          How much does it cost to mint a Secret Clan Warrior?
        </p>
        <p className="answer">
          TBA
        </p>
      </li>
      <li className="question-block">
        <p className="question">
          Will there be a pre-sale?
        </p>
        <p className="answer">No.</p>
      </li>
      <li className="question-block">
        <p className="question">
          What wallet do I need to use for minting?
        </p>
        <p className="answer">
          You will be able to mint using Phantom directly on our website secretclan.co
        </p>
      </li>
      <li className="question-block">
        <p className="question">
          Have more questions?
        </p>
        <p className="answer">
          Come through to our Discord to learn more.
        </p>
      </li>
    </ul>
  </section>
)

export default FAQSection