import * as React from "react"
import MainSection from "../sections/MainSection/index";
import WarriorSection from "../sections/WarriorSection/index";
import Seo from "../components/Seo";
import SpecsSection from "../sections/SpecsSection";
import WhySolSection from "../sections/WhySolSection";
import FAQSection from "../sections/FAQ";
import WallSection from "../sections/WallSection";


const IndexPage = () => {
  return (
    <>
      <MainSection />
      <SpecsSection />
      <WhySolSection />
      <WarriorSection />
      <WallSection />
      <FAQSection />
      <Seo data={{title: 'Home'}} />
    </>
  );
};

export default IndexPage

