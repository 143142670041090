import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import './index.css'

const Community = () => (
  <div className="community-block" id="community">
    <div className="about-community">
      <p className="comm-block-title">THE COMMUNITY</p>
      <p className="com-block-desc">Most projects don't focus on their communities,
        and they just bring a lot of people into their community before moving on
        to the next one. We think our greatest asset at Secret Clan is our community.
        That's why we devote all of our efforts towards creating a quality community
        rather than simply concentrating on quantity.
      </p>
      <p className="com-desc-2">
        Bots, cheats, phonies, or lurkers are prohibited from joining our community.
      </p>
    </div>
    <div className="community-img-block">
      <StaticImage className="community-img" alt="community-img" width={540} height={517}
        src="../../images/webp/community.webp" placeholder="blurred"
      />
    </div>
  </div>
)

export default Community