import * as React from "react"
import './index.css'
import * as globalStyles from '../../styles/index.module.css'
import { StaticImage } from "gatsby-plugin-image"

const SpecsSection = () => {
  return (
    <section className="specs-section">
      <div className={globalStyles.container}>
        <div className="specs-top-block">
          <div className="specs-left">
            <p className="specs-section-title">THE SPECS</p>
            <p className="section-secondary-title">THE SPECS</p>
          </div>
          <div className="specs-right">
            <p className="first-text-block">13 Warriors - inspired by the value behind Bushido. All Warriors are unique and
              programmatically generated from over 168 possible traits, including eyes, mouth,
              headwear, clothing, and more. Some are rarer than others.
            </p>
            <p>Secret Clan holders will get access to members-only areas such as the Discord, the networking wall, masterminds, events and the secret game room.
            </p>
            <div className="framed-text">Price to be announced</div>
          </div>
        </div>
      </div>
      <div className="specs-bottom-block">
        <StaticImage className="spec-left-img spec-img" alt="spec-background" width={120} height={150}
          src="../../images/webp/the-specs/1.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/2.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/3.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/4.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/5.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/6.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/7.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/8.webp" placeholder="blurred"
        />
        <StaticImage className="spec-img" alt="spec-background" width={150} height={150}
          src="../../images/webp/the-specs/9.webp" placeholder="blurred"
        />
        <StaticImage className="spec-right-img spec-img" alt="spec-background" width={120} height={150}
          src="../../images/webp/the-specs/10.webp" placeholder="blurred"
        />
      </div>
    </section>
  )
}

export default SpecsSection