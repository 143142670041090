import * as React from "react"
import TournamentTable from "../../components/TournamentTable"
import Community from "../../components/Community"
import * as globalStyles from '../../styles/index.module.css'
import './index.css'

const WhySolSection = () => (
  <section id="why-sol" className={`why-sol-section ${globalStyles.container}`}>
    <p className="graphic-title">why sol</p>
    <p className="solid-title">why sol?</p>
    <p className="why-sol-descr">We strongly believe that Solana is the best low-fees chain for the environment,
      and we wanted to make sure we provided a positive trading environment for our community.
    </p>
    <TournamentTable />
    <Community />
  </section>
)

export default WhySolSection