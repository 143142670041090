import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import './index.css'

const TournamentTable = () => (
  <>
    <div className="top-text">
      <p className="left-text">
        Chill and build a network in the Metaverse
      </p>
      <p className="right-text">
        Get a sit a the table of our secret rooms a meet new people.
        You get to connect with other entrepreneurs and crypto enthusiast like yourself,
        be part of something exclusive and make new relationship.
      </p>
    </div>
    {/* <div className="table-img-block">
      <StaticImage className="table-img" alt="table-img" width={1156} height={689}
        src="../../images/webp/table-sol.webp" placeholder="blurred"
      />
    </div> */}
    <div className="bottom-text">
      <p className="bottom-left">
        Strong Community
      </p>
      {/* <p className="bottom-right">
        December 15th
      </p> */}
    </div>
  </>
)

export default TournamentTable