import * as React from "react"
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image"
import * as globalStyles from '../../styles/index.module.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css'
import LeftArrow from "../../images/icons/left.svg"
import RightArrow from "../../images/icons/right.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const WarriorSection = () => {
  const customSlider = React.useRef({});
  const next = () => {
    customSlider.current.slickNext();
  };
  const previous = () => {
    customSlider.current.slickPrev();
  };

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    appendDots: dots => <ul className="dots-list">{dots}</ul>,
    customPaging: i => <div className="custom-dots"></div>,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 428,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <section className='arts-section'>
      <div className={globalStyles.container}>
        <h1 className="warrior-title">YOUR FUTURE WARRIOR</h1>
        <p className="warrior-desc">Launch Date - To Be Announced</p>
      </div>
      <div className="slider">
        <div className="navigation-of-slider">
          <button
            className="navigation-button prev-slide"
            aria-label="Left Arrow"
            onClick={previous}
          >
            <img className="arrow-icon" alt="" src={LeftArrow} />
          </button>
          <button
            className="navigation-button next-slide"
            aria-label="Right Arrow"
            onClick={next}
          >
            <img className="arrow-icon" alt="" src={RightArrow} />
          </button>
        </div>
        <Slider ref={slider => (customSlider.current = slider)} {...settings}>
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/3.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/1.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/10.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/11.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/5.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/1.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/2.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/4.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/7.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/8.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/9.webp"
          />
          <StaticImage
            placeholder="blurred"
            width={284} height={284} className="card" alt="Card"
            src="../../images/webp/6.webp"
          />
        </Slider>
      </div>
      <div className={`button-wrap ${globalStyles.container}`}>
        <a href="https://gate.secretclan.co" target="_blank" rel="noreferrer">
          <button className="mint-button" type="button">JOIN LOUNGE - 0.1 SOL</button>
        </a>
        <p className="btn-descr">Only lounge members can mint</p>
        <AnchorLink stripHash to="/#community" className="why-sol-btn" title="see why">
          SEE WHY
        </AnchorLink>
      </div>

    </section>
  );
};

export default WarriorSection