import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import './index.css'
import * as globalStyles from '../../styles/index.module.css'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const MainSection = () => {
  return (
    <section className='main-section'>
      <div className={`${globalStyles.container} main-wrap`}>
        <StaticImage className="metaplex-logo" alt="main-background" width={200} height={49}
          src="../../images/webp/metaplex.webp" placeholder="blurred"
        />
        <h2 className="page-title-white">
          Welcome to
        </h2>
        <h2 className="page-title-white page-title-transp">the secret Clan</h2>
        <p className="main-page-description">SC is a collection of 9000 Warriors defending the Solana blockchain. Your Warrior gives you access to members-only benefits like the networking wall, mastermind events, secret rooms and an authentic community of real Web3 enthusiasts. </p>
        <a href="https://gate.secretclan.co" target="_blank" rel="noreferrer">
          <button className="mint-button" type="button">JOIN LOUNGE - 0.1 SOL</button>
        </a>
        <p className="btn-descr">Only lounge members can mint</p>
        <AnchorLink stripHash to="/#community" className="why-sol-btn" title="see why">
          SEE WHY
        </AnchorLink>
      </div>
      <div className="img-block">
        <StaticImage className="main-img" alt="main-background" width={1200} height={700}
          src="../../images/webp/main-img.webp" placeholder="blurred"
        />
        <div className="color-overlay"></div>
      </div>
    </section>
  );
};

export default MainSection