import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import './index.css'
import * as globalStyles from '../../styles/index.module.css'

const WallSection = () => (
  <section className={`wall-section ${globalStyles.container}`}>
    <div className="motto-block">
      <p className="motto-text">
      "It's not what you know that determines your success, but who you know."
      </p>
    </div>
    <p className="wall-title">THE WALL</p>
    <div className="wall-mid-block">
      <div className="wall-left-text">
        <p>
          The Networking Wall is a place where you can find people, connections
          and resources to help you succeed. Share your social information, share
          projects, find partners, all on the SC WALL.
        </p>
        <p className="second-indent">
          Rarity of your SC warrior will determine your ranking on the wall.
        </p>
        <p className="third-indent">
          Secret clan is a place where people can grow and achieve anything.
        </p>
      </div>
      <div className="wall-right-img">
        <StaticImage className="wall-img" alt="wall-image" width={546} height={350}
          src="../../images/webp/wall.webp" placeholder="blurred"
        />
      </div>
    </div>
    <div className="bottom-text">
      <p className="bottom-left">
        WALL LAUNCH
      </p>
      <p className="bottom-right">
        EARLY 2022
      </p>
    </div>
    <div className="wall-bottom-block">
      <div className="wall-bottom-left">
        <p className="wall-bottom-title">CHILL AND VIBE</p>
        <p className="wall-bottom-desc">
          Meet with World-Class Entrepreneurs at Secret Clan. Whether you're looking
          for the proper founder, marketer, VC, or lawyer, who you know counts. Knowing
          the right people might save you a lot of time and money. The members of Secret
          Clan come from all walks of life.
        </p>
      </div>
      <div className="wall-bottom-mid">
        <StaticImage className="wall-logo" alt="wall logo image" width={111} height={102}
          src="../../images/webp/logo.webp" placeholder="blurred"
        />
      </div>
      <div className="wall-bottom-right">
        <p className="wall-bottom-title">MAKE MASTERMIND</p>
        <p className="wall-bottom-desc">
          At any moments, you'll be able to make mastermind and share your ideas with anyone
          of your new family. Our community is strong and well maintained
        </p>
      </div>
    </div>
  </section>
)

export default WallSection